import classNames from 'classnames';
import User from '../user';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Radio, Input, Button, Form, message } from 'antd';
import paypayIMg from '../../assets/paypay.jpg';
import seven from '../../assets/seven.jpg';
import family from '../../assets/family.jpg';
import lawson from '../../assets/lawson.jpg';
import { useSelector } from 'react-redux';
import { addOrderAPI, getNewOrderAPI } from '../../apis/order';
import { setCurrentOrder } from '../../store/modules/order';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../store/modules/takeaway';

const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [cardNumber, setCardNumber] = useState('');
  const [cardPassword, setCardPassword] = useState('');
  const [isPaymentMethodSelected, setIsPaymentMethodSelected] = useState(false);
  const [convenienceStoreBrand, setConvenienceStoreBrand] = useState('');
  const address = useSelector((state: any) => state.user.address);
  const { cartList } = useSelector((state: any) => state.foods);
  const navigate = useNavigate();
  const [uid, setUid] = useState(localStorage.getItem('userId'));
  const dispatch = useDispatch();

  const handleBack = () => {
    if (address) {
      navigate('/checkout'); // 导航到 checkout 页面
    } else {
      navigate(-1); // 返回到上一步
    }
  };

  const handlePaymentMethodChange = (e) => {
    const selectedMethod = e.target.value;
    setPaymentMethod(selectedMethod);
    console.log('paymentMethod', e.target.value);

    const flag = true;
    if (selectedMethod === 'credit' || selectedMethod === 'paypay') {
      setIsPaymentMethodSelected(flag);
    } else if (selectedMethod === 'convenience') {
      setIsPaymentMethodSelected(!flag); // 需要选择便利店品牌后才为 true
    } else {
      setIsPaymentMethodSelected(!flag);
    }
  };

  // 假设有一个函数来处理便利店品牌选择的逻辑
  const handleConvenienceStoreBrandChange = (e) => {
    setConvenienceStoreBrand(e.target.value);
    if (e.target.value) {
      setIsPaymentMethodSelected(true);
    } else {
      setIsPaymentMethodSelected(false);
    }
  };

  const handleOrderSubmit = async () => {
    if (isPaymentMethodSelected) {
      console.log('Order submitted');
      // 计算总价
      const totalPrice = cartList.reduce((a, c) => a + c.price * c.count, 0);
      console.log('totalPrice:', totalPrice);
      console.log('uid:', uid);
      console.log('cartList:', cartList);
      const orderFoodItems = cartList.map((item) => {
        let food_id = item.id;
        if (item.flavors && item.flavors.trim() !== '') {
          // 提取原始的 item.id
          const match = item.id.match(/^(\d+)\((.+)\)$/);
          food_id = match ? Number(match[1]) : item.id;
        }
        return {
          food_id: food_id,
          quantity: item.count,
          flavor: item.flavors,
          comboId: item.comboId ? item.comboId : null,
          selectedItems: item.selectedItems ? item.selectedItems : null,
        };
      });
      console.log('orderFoodItems:', orderFoodItems);
      const response = await addOrderAPI(uid, totalPrice, orderFoodItems);
      if (response.success === true) {
        dispatch(clearCart());
        const newOrder = await getNewOrderAPI(response.orderId);
        console.log('newOrder:', newOrder);
        dispatch(
          setCurrentOrder({
            oid: response.orderId,
            uid: newOrder.order.uid,
            amount: newOrder.order.amount,
            orderFoodItems: newOrder.order.orderFoodItems,
          })
        );
        message.success(`注文が完了しました。注文ID: ${response.orderId}`);
        navigate('/orderCompletion');
      } else {
        message.error('注文に失敗しました');
      }
    } else {
      message.error('支払い方法を選択してください');
    }
  };

  return (
    <div className="paymentContainer">
      <div className="scrollArea">
        <div className="header">
          <span className="text">支払い方法を選択してください</span>
          <User></User>
        </div>

        <div className="paymentMethods">
          <Radio.Group
            onChange={handlePaymentMethodChange}
            value={paymentMethod}
          >
            <Radio value="credit">クレジットカード</Radio>
            {paymentMethod === 'credit' && (
              <Form className="creditCardForm">
                <Form.Item label="カード番号">
                  <Input
                    type="text"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="パスワード">
                  <div className="password-container">
                    <Input
                      type="password"
                      value={cardPassword}
                      onChange={(e) => setCardPassword(e.target.value)}
                    />
                    <Button type="primary" onClick={() => { }}>
                      カード情報を確認
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
            <Radio value="paypay">PayPay</Radio>
            {paymentMethod === 'paypay' && (
              <img className="paypayIcon" src={paypayIMg} alt="PayPay" />
            )}
            <Radio value="convenience">コンビニ決済</Radio>
            {paymentMethod === 'convenience' && (
              <Radio.Group
                className="convenience-store-group"
                onChange={handleConvenienceStoreBrandChange}
              >
                <div className="convenience-store-item">
                  <div className="convenience-store-img">
                    <img src={seven} alt="セブンイレブン" />
                  </div>
                  <Radio value="sevenEleven">セブンイレブン</Radio>
                </div>
                <div className="convenience-store-item">
                  <div className="convenience-store-img">
                    <img src={family} alt="ファミリーマート" />
                  </div>
                  <Radio value="familyMart">ファミリーマート</Radio>
                </div>
                <div className="convenience-store-item">
                  <div className="convenience-store-img">
                    <img src={lawson} alt="ローソン" />
                  </div>
                  <Radio value="lawson">ローソン</Radio>
                </div>
              </Radio.Group>
            )}
          </Radio.Group>
        </div>

        <div className="fixedButtons">
          <div className="goBack" onClick={handleBack}>
            <span>前に戻る</span>
          </div>
          <div
            className={classNames('goToPreview', {
              active: isPaymentMethodSelected,
            })}
            onClick={handleOrderSubmit}
          >
            注文を完了
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
