import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const CheckoutComboItem = ({ selectedItems }) => {
  console.log("selectedItems", selectedItems);

  const navigate = useNavigate();
  const { token, userInfo } = useSelector((state: any) => state.user);
  // foodsList の各 food オブジェクトを分解
  const cartList = selectedItems.flatMap(({ foods }) =>
    foods.map(({ comboCategoryFoodId, id, name, picture, price, count, flavors }) => ({
      comboCategoryFoodId,
      id,
      name,
      picture,
      price,
      count,
      flavors,
    }))
  );

  // 総価格を計算
  const totalPrice = cartList.reduce((a, c) => a + c.price * c.count, 0);

  const dispatch = useDispatch();

  // カートの開閉状態を制御
  const [visible, setVisible] = useState(false);

  const onShow = () => {
    if (cartList.length > 0 && !visible) {
      setVisible(true);
    } else if (cartList.length > 0 && visible) {
      setVisible(false);
    } else return;
  };
  const handleClick = () => {
    if (token && userInfo.userId) {
      navigate("/checkout"); // token と userId がある場合、チェックアウトページに遷移
    } else {
      navigate("/messageLogin"); // それ以外の場合、ログインページに遷移
    }
  };
  return (
    <>
      {/* カートリスト */}
      {cartList
        .filter((item) => item.count > 0)
        .map((item) => {
          return (
            <div className="CheckoutComboItem" key={item.comboCategoryFoodId}>
            <img className="shopPic" src={item.picture} alt="" />
            <div className="main">
              <div className="skuInfo">
                <div className="name">{item.name}</div>
                <div className="flavors">
                  <span>{item.flavors}</span>
                </div>
              </div>
            </div>
            <div className="skuBtnWrapper btnGroup">
              {/* 数量コンポーネント */}
              <div className="goods-count">
                <span className="countText">注文数量：</span>
                <span className="count">{item.count}</span>
              </div>
            </div>
          </div>
          );
        })}
    </>
  );
};

export default CheckoutComboItem;