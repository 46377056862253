// ユーザー関連の全てのリクエスト
import { request } from '../../src/utils/request';
// 1. ログインリクエスト

export function loginAPI(formData) {
  return request({
    url: '/api/get-password',
    method: 'POST',
    data: formData,
  });
}

export function checkAddressRecordAPI(userId) {
  return request({
    url: '/api/check-user-address',
    method: 'POST',
    data: { userId },
  });
}

export async function addAddressAPI(
  userId,
  postalCodeInput,
  prefecture,
  city,
  addressLine,
  phoneNumber
) {
  try {
    const response = await request({
      url: '/api/insert-address',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userId,
        postalCodeInput,
        prefecture,
        city,
        addressLine,
        phoneNumber,
      },
      timeout: 5000, // タイムアウト時間を設定
    });

    // レスポンスデータを読み取る
    const data = response;
    console.log('data:', data);

    return { data };
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export function getAddressRecordAPI(userId) {
  return request({
    url: '/api/get-user-address',
    method: 'POST',
    data: { userId },
  });
}

export async function updateAddressAPI(
  addressId,
  postalCodeInput,
  prefecture,
  city,
  addressLine,
  phoneNumber
) {
  try {
    const response = await request({
      url: '/api/update-address',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        addressId,
        postalCodeInput,
        prefecture,
        city,
        addressLine,
        phoneNumber,
      },
      timeout: 5000, // タイムアウト時間を設定
    });

    // レスポンスデータを読み取る
    const data: any = response;
    console.log('data:', data);

    // レスポンスデータが成功メッセージを含むかチェック
    if (data.message === 'Address updated successfully') {
      return { data };
    } else {
      throw new Error(data.error || 'Failed to update address');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export function checkUsernameAPI(uname) {
  return request({
    url: '/api/check-user-uname',
    method: 'POST',
    data: { uname },
  });
}

export function uploadFileAPI(file) {
  const formData = new FormData();
  formData.append('avatar', file.originFileObj); // 'avatar' はサーバー側で期待されるフィールド名

  return request({
    url: '/api/upload-avatar',
    method: 'POST',
    data: formData,
    headers: {
      Accept: 'application/json',
    },
  });
}

export async function updateUserInfoAPI(userId, uname, gender) {
  try {
    const response = await request({
      url: '/api/update-userInfo',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userId,
        uname,
        gender,
      },
      timeout: 5000, // タイムアウト時間を設定
    });

    // レスポンスデータを読み取る
    const data: any = response;
    console.log('data:', data);

    // レスポンスデータが成功メッセージを含むかチェック
    if (data.message === 'User info updated successfully') {
      return { data };
    } else {
      throw new Error(data.error || 'Failed to update user info');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export function getUserInfoAPI(userId) {
  return request({
    url: `/api/get-userInfo`,
    method: 'GET',
    params: { userId }, // クエリパラメータとして params を使用
  });
}

export function getAvatarAPI(userId) {
  return request({
    url: `/api/get-avatar`,
    method: 'GET',
    params: { userId }, // クエリパラメータとして params を使用
  });
}

export async function updateFileAPI(userId, url, filename, mimetype, size) {
  try {
    const response = await request({
      url: '/api/update-files',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        userId,
        url,
        filename,
        mimetype,
        size,
      },
      timeout: 5000, // タイムアウト時間を設定
    });

    // レスポンスデータを読み取る
    const data: any = response;
    console.log('data:', data);

    // レスポンスデータが成功メッセージを含むかチェック
    if (
      data.message === 'File inserted successfully' ||
      data.message === 'File updated successfully'
    ) {
      return { data };
    } else {
      throw new Error(data.error || 'Failed to update file info');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export function sendEmailAPI(email: string) {
  return request({
    url: '/api/send-email',
    method: 'POST',
    data: { email },
  });
}

export function getCodeAPI(formData) {
  return request({
    url: '/api/get-code',
    method: 'POST',
    data: formData,
  });
}

export function getAllUserPermissionsAPI() {
  return request({
    url: '/api/get-allUserPermissions',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}