// 和用户相关的状态管理

import { createSlice } from "@reduxjs/toolkit";
import {
  setToken as _setToken,
  getToken,
  removeToken,
} from "../../utils/token";

const userStore = createSlice({
  name: "user",
  // 数据状态
  initialState: {
    token: getToken() || "",
    userInfo: {
      userId: null,
      phoneNumber: null,
      uname: null, // 新增属性
      avatar: null, // 新增属性
      gender: null, // 新增属性
      email: null, // 新增属性
      shopId: null, // 新增属性
    },
    address: {
      addressId: null,
      postalCode: "",
      prefecture: "",
      city: "",
      addressLine: "",
      phoneNumber: "",
    },
    shop:{
      shopId:null,
      shopName:null,
    }
  },
  // 同步修改方法
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      _setToken(action.payload);
    },
    setUserInfo(state, action) {
      state.userInfo = {
        userId: action.payload.userId || null,
        phoneNumber: action.payload.phoneNumber || null,
        uname: action.payload.uname || null,
        avatar: action.payload.avatar || null,
        gender: action.payload.gender || null,
        email: action.payload.email || null,
        shopId: action.payload.shopId || null,
      };
    },
    clearUserInfo(state: any) {
      state.token = "";
      state.userInfo = {};
      removeToken();
    },
    setAddress(state, action) {
      // 添加 setAddress 方法
      const {
        addressId,
        postalCodeInput,
        prefecture,
        city,
        addressLine,
        phoneNumber,
      } = action.payload;
      state.address.addressId = addressId;
      state.address.postalCode = postalCodeInput;
      state.address.prefecture = prefecture;
      state.address.city = city;
      state.address.addressLine = addressLine;
      state.address.phoneNumber = phoneNumber;
    },
    clearAddress(state: any) {
      // 添加 clearAddress 方法
      state.address = {};
    },
    updateAddress(state, action) {
      // 添加 updateAddress 方法
      const {
        id,
        postalCodeInput,
        prefecture,
        city,
        addressLine,
        phoneNumber,
      } = action.payload;
      state.address.addressId = id;
      state.address.postalCode = postalCodeInput;
      state.address.prefecture = prefecture;
      state.address.city = city;
      state.address.addressLine = addressLine;
      state.address.phoneNumber = phoneNumber;
    },
    updateShop(state, action) {
      const { shopId, shopName } = action.payload;
      state.shop.shopId = shopId;
      state.shop.shopName = shopName;
    },
  },
});

// 解构出actionCreater

const {
  setToken,
  setUserInfo,
  clearUserInfo,
  setAddress,
  clearAddress,
  updateAddress,
  updateShop,
} = userStore.actions;

// 获取reducer函数

const userReducer = userStore.reducer;

// 登录获取token异步方法封装
const fetchLogin = (data) => {
  return (dispatch) => {
    const { token, userId, phoneNumber = null, email = null, shopId = null } = data;
    // 设置 token
    dispatch(setToken(token));

    // 设置 userInfo
    dispatch(setUserInfo({ userId, phoneNumber, email,shopId }));
  };
};

export {
  fetchLogin,
  setToken,
  setUserInfo,
  clearUserInfo,
  setAddress,
  clearAddress,
  updateAddress,
  updateShop,
};

export default userReducer;
