import { request } from '../../src/utils/request';

export function getFoodsAPI(shopName?: string) {
  const url = shopName ? `/api/get-foods?shopName=${shopName}` : '/api/get-foods';
  return request({
    url: url,
    method: 'GET',
  });
}

export async function updateFoodTagAPI(id, tag) {
  try {
    const response = await request({
      url: '/api/update-food-tag',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id,
        tag,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Food tag updated successfully') {
      return { status: true };
    } else {
      throw new Error(data.error || 'Failed to update food tag');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function updateFoodAPI(
  id,
  newId,
  name,
  like_ratio_desc,
  month_saled,
  unit,
  price,
  description,
  tagList
) {
  try {
    const response = await request({
      url: '/api/update-food',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id,
        newId,
        name,
        like_ratio_desc,
        month_saled,
        unit,
        price,
        description,
        tagList,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Food updated successfully') {
      return { status: true };
    } else {
      throw new Error(data.error || 'Failed to update food');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function updateStatusAPI(id, status) {
  try {
    const response = await request({
      url: '/api/update-food-status',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id,
        status,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Food status updated successfully') {
      return { status: true };
    } else {
      throw new Error(data.error || 'Failed to update status');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function addFoodAPI(
  id,
  name,
  like_ratio_desc,
  month_saled,
  unit,
  food_tag_list,
  price,
  description,
  tag,
  count,
  status,
  shop_id
) {
  try {
    const response = await request({
      url: '/api/add-food',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id,
        name,
        like_ratio_desc,
        month_saled,
        unit,
        food_tag_list,
        price,
        description,
        tag,
        count,
        status,
        shop_id,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Food added successfully') {
      return { success: true };
    } else {
      throw new Error(data.error || 'Failed to add food');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function deleteFoodsAPI(ids) {
  try {
    const response = await request({
      url: '/api/delete-food',
      method: 'POST',
      data: {
        ids: ids,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Foods deleted successfully') {
      return { status: true };
    } else {
      throw new Error(data.error || 'Failed to delete foods');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function uploadFoodPictureAPI(file) {
  const formData = new FormData();
  formData.append('foodPicture', file.originFileObj); // 'avatar' 是服务器端期望的字段名

  return await request({
    url: '/api/upload-foodPicture',
    method: 'POST',
    data: formData,
    headers: {
      Accept: 'application/json',
    },
  });
}

export async function updateFoodPictureAPI(fid, url, filename, mimetype, size) {
  try {
    const response = await request({
      url: '/api/update-foodPicture',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fid,
        url,
        filename,
        mimetype,
        size,
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);

    // 检查响应数据是否包含成功消息
    if (
      data.message === 'File inserted successfully' ||
      data.message === 'File updated successfully'
    ) {
      return { statusCode: 201, data };
    } else {
      throw new Error(data.error || 'Failed to update file info');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function searchFoodsAPI({ foodName, food_tag, id, status }) {
  try {
    // 构建查询参数
    const queryParams = new URLSearchParams();
    if (foodName) queryParams.append('foodName', foodName);
    if (food_tag) queryParams.append('food_tag', food_tag);
    if (id) queryParams.append('id', id);
    if (status) queryParams.append('status', status);

    // 发送请求
    const response = await request({
      url: `/api/search-foods?${queryParams.toString()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 5000, // 设置超时时间
    });

    // 读取响应数据
    const data: any = response;
    console.log('data:', data);
    console.log('data.resultFood:', data.resultFood);

    // 检查响应数据是否包含成功消息
    if (data.message === 'Search food successfully') {
      return { statusCode: 200, resultFood: data.resultFood };
    } else {
      throw new Error(data.error || 'Failed to fetch food data');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

