import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import Dropdown from '../../Dropdown';
import classNames from 'classnames';

const ComboItem = ({ food, selectedItems, maxSelectable, onFoodSelect }) => {
  const { comboCategoryFoodId, id, name, picture, unit, description, food_tag_list, month_saled, like_ratio_desc, flavors, tag } = food;
  const [selectedFlavor, setSelectedFlavor] = useState(flavors[0]);
  const isInitialRender = useRef(true);
  const handleFlavorSelect = (flavor) => {
    setSelectedFlavor(flavor);
  };
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      onFoodSelect(tag, id, comboCategoryFoodId, selectedFlavor);
      console.log("tag" + selectedFlavor);
    }
  }, [selectedFlavor]);
  return (
    <dd
      className={classNames("cate-goods", {
        "data-selected": selectedItems[tag]?.some(item => item.comboCategoryFoodId === comboCategoryFoodId),
        "data-unselected": selectedItems[tag]?.length === maxSelectable && !selectedItems[tag]?.some(item => item.comboCategoryFoodId === comboCategoryFoodId),
      })}
      onClick={() => onFoodSelect(tag, id, comboCategoryFoodId, selectedFlavor)}
    >
      <div className="goods-img-wrap">
        <img
          src={picture || '/uploads/default/noPicture.jpg'}
          alt=""
          className={classNames("goods-img", "combo-img")}
        />
      </div>
      <div className="goods-info">
        <div className="goods-desc">
          <div className="goods-title">{name}</div>
          <div className="goods-detail">
            <div className="goods-unit">{unit}</div>
            <div className="goods-detail-text">{description}</div>
          </div>
          <div className="goods-tag">{Array.isArray(food_tag_list) ? food_tag_list.join(' ') : ''}</div>
          <div className="goods-sales-volume">
            <span className="goods-num">{month_saled ? `月販${month_saled}` : ''}</span>
            <span className="goods-num">{like_ratio_desc}</span>
          </div>
        </div>
        {flavors.length > 0 && (
          <Dropdown flavors={flavors} onFlavorSelect={handleFlavorSelect} />
        )}
      </div>
    </dd >
  );
};

export default ComboItem;