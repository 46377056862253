import NavBar from "./components/NavBar";
import Menu from "./components/Menu";
import Cart from "./components/Cart";
import FoodsCategory from "./components/FoodsCategory";

import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchFoodsList } from "./store/modules/takeaway";
import { setUserInfo } from "./store/modules/user";
import { useEffect } from "react";

const App = () => {
  // 触发action执行
  // 1. useDispatch -> dispatch 2. actionCreater导入进来 3.useEffect
  const dispatch: any = useDispatch();

  useEffect(() => {
    const hash = window.location;
    console.log(hash);

    // 从 localStorage 中读取 userId 并存储到 Redux 状态中
    const userId = localStorage.getItem("userId");
    if (userId) {
      dispatch(setUserInfo({ userId: userId }));
    }
    dispatch(fetchFoodsList());
  }, [dispatch, localStorage.getItem("shopName")]);

  useEffect(() => {
    // 禁用 WebSocket 连接
    window.WebSocket = undefined;
  }, []);

  // 获取foodsList渲染数据列表
  // 1. useSelector
  const { foodsList, activeIndex, cartList } = useSelector(
    (state: any) => state.foods
  );

  return (
    <div className="home">
      {/* 导航 */}
      <NavBar />

      {/* 内容 */}
      <div className="content-wrap">
        <div className="content">
          <Menu />
          <div className="list-content">
            <div className="goods-list">
              {/* 外卖商品列表 */}
              {foodsList.map((item, index) => {
                return (
                  activeIndex === index && (
                    <FoodsCategory
                      key={item.tag}
                      // 列表标题
                      name={item.name}
                      // 列表商品
                      foods={item.foods}
                      cartList={cartList}
                    />
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* 购物车 */}
      <Cart foodsList={foodsList} />
    </div>
  );
};

export default App;
