import App from '../../App';
import Comments from '../Comments/Comments';
import { createBrowserRouter, createHashRouter } from 'react-router-dom';
import Login from '../Login';
import Checkout from '../CheckOut';
import Address from '../address';
import Payment from '../Payment';
import UpdateAddress from '../updateAddress';
import ProfileSettings from '../ProfileSettings';
import withPageReload from '../../withPageReload';
import QRCodeGenerator from '../QRCodeGenerator';
import Shop from '../Shop';
import React from 'react';
import QRLogin from '../QRLogin';
import OrderCompletion from '../orderCompletion';
import PurchaseHistory from '../PurchaseHistory';
import MessageLogin from '../MessageLogin';
import OrderCheck from '../OrderCheck';
import TStest from '../test1';
import ShopPage from '../ShopPage';

const routes = [
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/comments',
    element: <Comments />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/address',
    element: <Address />,
  },
  {
    path: '/payment',
    element: <Payment />,
  },
  {
    path: '/updateAddress',
    element: <UpdateAddress />,
  },
  {
    path: '/profileSettings',
    element: <ProfileSettings />,
  },
  {
    path: '/shop',
    element: <Shop />,
  },
  {
    path: '/qrcode',
    element: <QRCodeGenerator />,
  },
  {
    path: '/qrlogin',
    element: <QRLogin />,
  },
  {
    path: '/orderCompletion',
    element: <OrderCompletion />,
  },
  {
    path: '/purchaseHistory',
    element: <PurchaseHistory />,
  },
  {
    path: '/messageLogin',
    element: <MessageLogin />,
  },
  {
    path: '/orderCheck',
    element: <OrderCheck />,
  },
  {
    path: '/test1',
    element: <TStest />,
  },
  {
    path: '/shopPage/:shopUrl',
    element: <ShopPage />,
  },
];

const wrappedRoutes = routes.map((route) => ({
  ...route,
  element: React.createElement(withPageReload(route.element.type)),
}));
const router = createHashRouter(wrappedRoutes);

export default router;
