import './index.scss'

const CartComboItemCount = ({ count }) => {
  return (
    <div className="goods-count">
      <span className="count">{count}</span>
    </div>
  )
}

export default CartComboItemCount
