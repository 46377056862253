import React from 'react';
import { Menu } from 'antd';
import { HomeOutlined, AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './index.scss';

const ManagementNavbar = ({ onNavClick }) => {
  return (
    <Menu mode="horizontal" defaultSelectedKeys={['list']} onClick={onNavClick}>
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <Link to={'/'}>ホーム</Link>
      </Menu.Item>
      <Menu.Item key="list" icon={<UnorderedListOutlined />}>
        料理リスト
      </Menu.Item>
      <Menu.Item key="combo" icon={<AppstoreOutlined />}>
        セットメニュー
      </Menu.Item>
    </Menu>
  );
};

export default ManagementNavbar;