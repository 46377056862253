import { request } from '../../src/utils/request';

export async function getComboDetailAPI(comboId: number, shopId: number) {
  try {
    // クエリパラメータを構築
    const queryParams = new URLSearchParams();
    queryParams.append('comboId', comboId.toString());
    queryParams.append('shopId', shopId.toString());

    // リクエストを送信
    const response = await request({
      url: `/api/get-combo-detail?${queryParams.toString()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 5000, // タイムアウト時間を設定
    });

    // レスポンスデータを読み取る
    const data: any = response;
    console.log('data:', data);

    // レスポンスデータに成功メッセージが含まれているかを確認
    if (data) {
      return { statusCode: 200, comboDetail: data };
    } else {
      throw new Error(data.error || 'Failed to fetch combo detail');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function getAllComboDetailAPI(shopId: number) {
  try {
    // クエリパラメータを構築
    const queryParams = new URLSearchParams();
    queryParams.append('shopId', shopId.toString());

    // リクエストを送信
    const response = await request({
      url: `/api/get-allComboDetail?${queryParams.toString()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 5000, // タイムアウト時間を設定
    });

    // レスポンスデータを読み取る
    const data: any = response;

    // レスポンスデータに成功メッセージが含まれているかを確認
    if (data) {
      return { statusCode: 200, comboDetails: data };
    } else {
      throw new Error(data.error || 'Failed to fetch all combo details');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}