import { useSelector, useDispatch } from 'react-redux';
import User from '../user';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getAllOrderAPI } from '../../apis/order';
import { useEffect, useState } from 'react';
import { Card, List, Pagination, Switch, Button, message } from 'antd';
import logo from '../../assets/logo.png';
import OrderStatusTag from '../OrderStatusTag';
import { addCart,addComboToCart } from '../../store/modules/takeaway';
import PurchaseHistoryComboItem from './PurchaseHistoryComboItem';

const PurchaseHistory = () => {
  const { userInfo } = useSelector((state: any) => state.user);
  const { cartList } = useSelector((state: any) => state.foods);
  const dispatch = useDispatch();
  // dispatch(removeCartItem({ itemId }));
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [visible, setVisible] = useState({});
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleLogoClick = () => {
    navigate('/');
  };

  const paginatedOrders = orders
    .sort((a, b) => new Date(b.ctime).getTime() - new Date(a.ctime).getTime()) // 按照 ctime 倒序排列
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handleToggleVisibility = (oid) => {
    setVisible((prevVisible) => ({
      ...prevVisible,
      [oid]: !prevVisible[oid],
    }));
  };


  const handleReorder = (foodItem) => {
    // cartListの中に同じnameとflavorsの項目があるかどうかを確認する
    const existingItem = cartList.find(
      (item) => item.name === foodItem.name && item.flavors === foodItem.flavor
    );

    if (existingItem) {
      // 既存の項目がある場合、その項目のcountを1増やす
      dispatch(
        addCart({
          ...existingItem,
          count: existingItem.count + 1,
        })
      );
      message.success('再度購入しました'); // 成功メッセージを表示する
    } else if (foodItem.comboId !== null) {
      dispatch(addComboToCart({
        id: foodItem.food_id,
        picture: foodItem.file_path,
        name: foodItem.name,
        price: foodItem.price,
        count: 1,
        flavors: '',
        comboId: foodItem.comboId,
        selectedItems: [
          { foods: foodItem.comboRows }
        ],
      }));
      message.success('再度購入しました'); // 成功メッセージを表示する
    } else {
      // 新しい項目を作成し、cartListに追加する
      const newId = foodItem.flavor ? `${foodItem.food_id}(${foodItem.flavor})` : `${foodItem.food_id}`;
      dispatch(
        addCart({
          id: newId,
          picture: foodItem.file_path,
          name: foodItem.name,
          price: foodItem.price,
          count: 1,
          flavors: foodItem.flavor || '',
        })
      );
      message.success('再度購入しました'); // 成功メッセージを表示する
    }
  }
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getAllOrderAPI(userInfo.userId);
        console.log('response:', response); // 添加日志
        if (response.success) {
          setOrders(response.result.orders);
          console.log('orders:', response.result.orders); // 添加日志
          // 初始化 visible 状态，使所有订单的 List 组件内容都显示
          const initialVisibleState = response.result.orders.reduce(
            (acc, order) => {
              acc[order.oid] = true;
              return acc;
            },
            {}
          );
          setVisible(initialVisibleState);
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    if (userInfo.userId) {
      fetchOrders();
    }
  }, [userInfo.userId]);

  return (
    <div className="purchaseHistoryContainer">
      <div className="scrollArea">
        <div className="header">
          <div className="backToTop">
            <img
              className="login-logo"
              src={logo}
              alt=""
              onClick={handleLogoClick}
            />
          </div>
          <span className="text">購入履歴</span>
          <User></User>
        </div>
        <div className="order-container">
          {paginatedOrders.length === 0 ? (
            <Card title="過去の購入履歴がありません" style={{ marginBottom: 16 }} />
          ) : (paginatedOrders.map((order) => (
            <Card
              key={order.oid}
              title={`注文番号: ${order.oid}`}
              style={{ marginBottom: 16 }}
              extra={
                <div>
                  <OrderStatusTag status={order.status} />
                  <Switch
                    checked={visible[order.oid]}
                    onChange={() => handleToggleVisibility(order.oid)}
                    style={{ marginRight: 8 }}
                  />
                </div>
              }
            >
              <p>合計金額: {Number(order.amount).toLocaleString()} 円</p>
              <p>注文日時: {order.ctime}</p>
              {visible[order.oid] && (
                <List
                  itemLayout="horizontal"
                  dataSource={order.orderFoodItems}
                  renderItem={(foodItem: any) => (
                    <List.Item>
                      <img
                        className="shopPic"
                        src={foodItem.file_path}
                        alt=""
                      />
                      <List.Item.Meta
                        title={`料理名: ${foodItem.name}`}
                        description={
                          <>
                            <p>注文数量: {foodItem.quantity}</p>
                            {foodItem.flavor && <p>風味: {foodItem.flavor}</p>}
                            <p>
                              価格: {Number(foodItem.price).toLocaleString()} 円
                            </p>
                            <Button type="primary" onClick={() => handleReorder(foodItem)}>
                              再度購入
                            </Button>
                            {foodItem.comboId !== null && foodItem.comboRows &&
                              <PurchaseHistoryComboItem selectedItems={foodItem.comboRows} />
                            }
                          </>
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </Card>
          )))}
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={orders.length}
          onChange={handlePageChange}
          style={{ textAlign: 'center', marginTop: 16 }}
        />
      </div>
    </div>
  );
};

export default PurchaseHistory;
