import React, { useState } from 'react';
import './index.scss';
import { Modal, Button, message } from 'antd';
import classNames from 'classnames';
import ComboItem from './ComboItem';
import { useDispatch, useSelector } from "react-redux";
import { addComboToCart } from "../../store/modules/takeaway";

const ComboSelect = ({ foodProps, visible, combo, onVisibleChange }) => {
  const {
    id,
    picture,
    name,
    unit,
    description,
    food_tag_list,
    month_saled,
    like_ratio_desc,
    price,
    tag,
    count,
    flavors,
    comboId,
  } = foodProps;
  const [selectedItems, setSelectedItems] = useState([]);
  const { comboName, tags } = combo[0];
  const dispatch = useDispatch();
  const onClose = () => {
    onVisibleChange(false);
    setSelectedItems([]);
  };

  const handleFoodSelect = (tag, id, comboCategoryFoodId, selectedFlavor) => {
    setSelectedItems((prevSelectedItems) => {
      console.log("prevSelectedItems" + JSON.stringify(prevSelectedItems, null, 2));
      const currentSelected = prevSelectedItems[tag] || [];
      const isSelected = currentSelected.some(item => item.comboCategoryFoodId === comboCategoryFoodId);
      if (isSelected) {
        const existingItem = currentSelected.find(item => item.comboCategoryFoodId === comboCategoryFoodId);
        if (existingItem.selectedFlavor === selectedFlavor) {
          return prevSelectedItems; // 既に選択されていて、かつフレーバーが変更されていない場合、何もしない
        } else {
          // 風味を更新
          return {
            ...prevSelectedItems,
            [tag]: currentSelected.map(item =>
              item.comboCategoryFoodId === comboCategoryFoodId ? { ...item, selectedFlavor: selectedFlavor } : item
            ),
          };
        }
      }

      // 現在の選択数が最大選択数未満の場合、新しい項目を追加
      if (currentSelected.length < tags.find((category) => Number(category.tag) === tag).maxSelectable) {
        return {
          ...prevSelectedItems,
          [tag]: [...currentSelected, { comboCategoryFoodId, id, selectedFlavor }],
        };
      } else {
        // 現在の選択数が最大選択数に達している場合、最後に追加された項目を新しい項目に置き換える
        return {
          ...prevSelectedItems,
          [tag]: [...currentSelected.slice(0, -1), { comboCategoryFoodId, id, selectedFlavor }],
        };
      }
    });
  };

  const handleConfirm = () => {
    for (const category of combo[0].tags) {
      const currentSelected = selectedItems[category.tag] || [];
      if (currentSelected.length < category.maxSelectable) {
        message.warning(`${category.name}の下にはまだ選択できる料理があります`);
        return;
      }
    }
    const filteredCombo = combo[0].tags.map(category => ({
      ...category,
      foods: category.foods
        .filter(food =>
          selectedItems[category.tag]?.some(selectedItem => selectedItem.comboCategoryFoodId === food.comboCategoryFoodId)
        )
        .map(food => {
          const selectedItem = selectedItems[category.tag]?.find(selectedItem => selectedItem.comboCategoryFoodId === food.comboCategoryFoodId);
          return {
            ...food,
            flavors: selectedItem.selectedFlavor ? selectedItem.selectedFlavor : ''
          };
        })
    }));
    console.log('filteredCombo', filteredCombo);
    dispatch(addComboToCart({
      id,
      picture,
      name,
      unit,
      description,
      food_tag_list,
      month_saled,
      like_ratio_desc,
      price,
      tag,
      count,
      flavors: '',
      comboId,
      selectedItems: filteredCombo,
    }));
    onClose();
  };

  return (
    console.log('combo', combo),
    <div className="comboSelectContainer">
      <div
        className={classNames("comboOverlay", visible && "visible")}
        onClick={() => onClose()}
      />
      <Modal
        title={`${comboName}　料理選択`}
        visible={visible}
        onCancel={onClose}
        onOk={handleConfirm}
        okText="確認"
        cancelText="キャンセル"
        className='comboSelectModal'
      >
        {tags.map((category) => (
          <div key={category.tag}>
            <h3>{category.name} ({category.maxSelectable}つ選択できます。)</h3>
            <ul>
              {category.foods.map((food) => (
                <ComboItem
                  key={food.comboCategoryFoodId}
                  food={food}
                  selectedItems={selectedItems}
                  maxSelectable={category.maxSelectable}
                  onFoodSelect={handleFoodSelect}
                />
              ))}
            </ul>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default ComboSelect;