import { useSelector } from 'react-redux';
import User from '../user';
import './index.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getAllShopOrdersAPI, updateOrderStatusAPI } from '../../apis/order';
import { useEffect, useState } from 'react';
import { Card, List, Pagination, Switch, Image } from 'antd';
import logo from '../../assets/logo.png';
import OrderStatusManagerTag from '../OrderStatusManagerTag';

const OrderCheck = () => {
  const { userInfo } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [visible, setVisible] = useState({});
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleLogoClick = () => {
    navigate('/');
  };

  const paginatedOrders = orders
    .sort((a, b) => new Date(b.ctime).getTime() - new Date(a.ctime).getTime()) // 按照 ctime 倒序排列
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handleToggleVisibility = (oid) => {
    setVisible((prevVisible) => ({
      ...prevVisible,
      [oid]: !prevVisible[oid],
    }));
  };

  const handleStatusChange = async (oid, newStatus) => {
    try {
      const result = await updateOrderStatusAPI(oid, newStatus);
      console.log('result:', result); // 添加日志
      if (result.success) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.oid === result.oid ? { ...order, status: result.status } : order
          )
        );
      } else {
        console.error('Failed to update order status');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const shop_id = "30009257";
        const response = await getAllShopOrdersAPI(shop_id);
        console.log('response:', response); // 添加日志
        if (response.success) {
          setOrders(response.result.orders);
          console.log('orders:', response.result.orders); // 添加日志
          // 初始化 visible 状态，使所有订单的 List 组件内容都显示
          const initialVisibleState = response.result.orders.reduce(
            (acc, order) => {
              acc[order.oid] = true;
              return acc;
            },
            {}
          );
          setVisible(initialVisibleState);
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    if (userInfo.userId) {
      fetchOrders();
    }
  }, [userInfo.userId]);

  return (
    <div className="purchaseHistoryContainer">
      <div className="scrollArea">
        <div className="header">
          <div className="backToTop">
            <img
              className="login-logo"
              src={logo}
              alt=""
              onClick={handleLogoClick}
            />
          </div>
          <span className="text">注文管理</span>
          <User></User>
        </div>
        <div className="order-container">
          {paginatedOrders.length === 0 ? (
            <Card title="過去の購入履歴がありません" style={{ marginBottom: 16 }} />
          ) : (paginatedOrders.map((order) => (
            <Card
              key={order.oid}
              title={<div>
                {order.status === 'Pending' && (
                  <img className='newIcon' src="/uploads/default/new.jpg" alt="New" />
                )}
                <span style={{ marginLeft: '15px' }}>注文番号: {order.oid}</span>
              </div>}
              style={{ marginBottom: 16 }}
              extra={
                <div className='extraContainer'>
                  <OrderStatusManagerTag status={order.status} onStatusChange={(newStatus) => handleStatusChange(order.oid, newStatus)} />
                  <Switch
                    checked={visible[order.oid]}
                    onChange={() => handleToggleVisibility(order.oid)}
                    style={{ marginRight: 8 }}
                  />
                </div>
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image
                  width={50}
                  height={50}
                  src="/uploads/default/table.png"
                  alt={`Table ${order.tableNumber}`}
                  style={{ marginRight: 16 }}
                  preview={false}
                />
                {order.tableNumber && (
                  <div className="cartCornerMark">{order.tableNumber}</div>
                )}
                <div>テーブル番号: {order.tableNumber}</div>
              </div>
              <p>合計金額: {Number(order.amount).toLocaleString()} 円</p>
              <p>注文日時: {order.ctime}</p>
              {visible[order.oid] && (
                <List
                  itemLayout="horizontal"
                  dataSource={order.orderFoodItems}
                  renderItem={(foodItem: any) => (
                    <List.Item>
                      <img
                        className="shopPic"
                        src={foodItem.file_path}
                        alt=""
                      />
                      <List.Item.Meta
                        title={`料理名: ${foodItem.name}`}
                        description={
                          <>
                            <p>注文数量: {foodItem.quantity}</p>
                            {foodItem.flavor && <p>風味: {foodItem.flavor}</p>}
                            <p>
                              価格: {Number(foodItem.price).toLocaleString()} 円
                            </p>
                          </>
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </Card>
          )))}
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={orders.length}
          onChange={handlePageChange}
          style={{ textAlign: 'center', marginTop: 16 }}
        />
      </div>
    </div>
  );
};

export default OrderCheck;
