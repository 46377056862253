import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCart, decreCount } from '../../../store/modules/takeaway';
import './index.scss';
import Dropdown from '../../Dropdown';
import { Button, message } from 'antd';
import { getComboDetailAPI } from '../../../apis/combo';
import ComboSelect from '../../ComboSelect';
const Foods = ({
  id,
  picture,
  name,
  unit,
  description,
  food_tag_list,
  month_saled,
  like_ratio_desc,
  price,
  tag,
  count,
  flavors,
  comboId,
  cartList,
}) => {
  const [isInCart, setIsInCart] = useState(false);
  const [selectedFlavor, setSelectedFlavor] = useState(flavors[0]);
  const { shopId } = useSelector(
    (state: any) => state.user.shop
  );
  const [visible, setVisible] = useState(false);
  const [comboDetail, setComboDetail] = useState(null);
  const foodProps = {
    id,
    picture,
    name,
    unit,
    description,
    food_tag_list,
    month_saled,
    like_ratio_desc,
    price,
    tag,
    count,
    flavors,
    comboId,
  };
  useEffect(() => {
    // 检查cartList中是否存在当前id
    const itemInCart = cartList.some((item) => item.id === id);
    setIsInCart(itemInCart);
  }, [cartList, id]);

  useEffect(() => {
    // 更新itemCount为cartList中对应id的count值
    const item = cartList.find((item) => item.id === id);
    if (item) {
      setItemCount(item.count);
    }
  }, [cartList, id]);

  const [itemCount, setItemCount] = useState(0);

  const dispatch = useDispatch();

  const handleAdd = () => {
    setItemCount(itemCount + 1);
    dispatch(
      addCart({
        id,
        picture,
        name,
        unit,
        description,
        food_tag_list,
        month_saled,
        like_ratio_desc,
        price,
        tag,
        count,
        flavors: selectedFlavor || '',
      })
    );
    const cartIcon = document.getElementById('cart-icon');
    if (cartIcon) {
      cartIcon.classList.add('animate');
      setTimeout(() => {
        cartIcon.classList.remove('animate');
      }, 1000); // 动画持续时间
    }
  };

  const handleAddToCart = () => {
    // 查找cartList中是否有相同name和flavors的项
    const existingItem = cartList.find(
      (item) => item.name === name && item.flavors === selectedFlavor
    );
    if (existingItem) {
      dispatch(
        addCart({
          ...existingItem,
          count: existingItem.count + 1,
        })
      );
    } else {
      const newId = `${id}(${selectedFlavor})`;
      dispatch(
        addCart({
          id: newId,
          picture,
          name,
          unit,
          description,
          food_tag_list,
          month_saled,
          like_ratio_desc,
          price,
          tag,
          count: 1,
          flavors: selectedFlavor || [],
        })
      );
    }
    const cartIcon = document.getElementById('cart-icon');
    if (cartIcon) {
      cartIcon.classList.add('animate');
      setTimeout(() => {
        cartIcon.classList.remove('animate');
      }, 1000); // 动画持续时间
    }
  };

  const handleDecrease = () => {
    if (itemCount > 0) {
      setItemCount(itemCount - 1);
      dispatch(
        decreCount({
          id,
          picture,
          name,
          unit,
          description,
          food_tag_list,
          month_saled,
          like_ratio_desc,
          price,
          tag,
          count,
        })
      );
    }
  };

  const handleFlavorSelect = (flavor) => {
    setSelectedFlavor(flavor);
  };

  const handleComboSelect = async (comboId) => {
    try {
      if (isInCart) {
        message.warning('カートに既にあるセットを再度選択することはできません');
        return;
      }
      const response = await getComboDetailAPI(comboId, shopId);
      if (response.statusCode === 200) {
        console.log('Combo detail:', response.comboDetail);
        setComboDetail(response.comboDetail);
        setVisible(true);
      } else {
        console.error('Failed to fetch combo detail:', response);
      }
    } catch (error) {
      console.error('Error fetching combo detail:', error);
    }
  };

  return (
    <dd className="cate-goods">
      <div className="goods-img-wrap">
        <img
          src={picture || '/uploads/default/noPicture.jpg'}
          alt=""
          className="goods-img"
          onClick={() => {
            if (comboId) {
              handleComboSelect(comboId);
            } else if (flavors.length > 0) {
              handleAddToCart();
            } else {
              handleAdd();
            }
          }}
        />
      </div>
      <div className="goods-info">
        <div className="goods-desc">
          <div className="goods-title">{name}</div>
          <div className="goods-detail">
            <div className="goods-unit">{unit}</div>
            <div className="goods-detail-text">{description}</div>
          </div>
          <div className="goods-tag">{food_tag_list.join(' ')}</div>
          <div className="goods-sales-volume">
            <span className="goods-num">{month_saled ? `月販${month_saled}` : ''}</span>
            <span className="goods-num">{like_ratio_desc}</span>
          </div>
        </div>
        {flavors.length > 0 && (
          <Dropdown flavors={flavors} onFlavorSelect={handleFlavorSelect} />
        )}
        <div className="goods-price-count">
          <div className="goods-price">
            {price.toLocaleString()}
            <span className="goods-price-unit">&nbsp;円</span>
          </div>
          {flavors.length === 0 && comboId === null ? (
            <div className="goods-count">
              {/* 如果商品在购物车中且数量大于0，显示减去数量的按钮 */}
              {isInCart && itemCount > 0 && (
                <span className="minus" onClick={handleDecrease}></span>
              )}
              {isInCart && itemCount > 0 && (
                <span className="count">{itemCount}</span>
              )}
              {/* 添加商品 */}
              <span className="plus" onClick={handleAdd}></span>
            </div>
          ) : (
            comboId === null && (
              <Button className="add-to-cart" onClick={handleAddToCart}>
                カートに追加
              </Button>)
          )}
          {/* comboId が存在する場合、comboSelect ボタンを表示 */}
          {comboId && (
            <Button className="combo-select" onClick={() => handleComboSelect(comboId)}>
              セット選択
            </Button>
          )}
        </div>
      </div>
      {comboDetail && (
        <ComboSelect
          foodProps={foodProps}
          visible={visible}
          combo={comboDetail}
          onVisibleChange={() => setVisible(false)}
        />
      )}
    </dd>
  );
};

export default Foods;
