import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCartComboItemFlavor,
} from "../../store/modules/takeaway";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import CartDropdown from "../cartDropdown";
import CartComboItemCount from "./CartComboItemCount";

const CartComboItem = ({ selectedItems, foodItems, originId }) => {
  console.log("selectedItems", selectedItems);

  const navigate = useNavigate();
  const { token, userInfo } = useSelector((state: any) => state.user);
  // foodsListの各foodオブジェクトを分解
  const cartList = selectedItems.flatMap(({ foods }) =>
    foods.map(({ comboCategoryFoodId, id, name, picture, price, count, flavors }) => ({
      comboCategoryFoodId,
      id,
      name,
      picture,
      price,
      count,
      flavors,
    }))
  );
  console.log("fooditems", foodItems);

  const cartFlavors = cartList.flatMap((cartItem) => {
    const food = foodItems.find((foodItem) => foodItem.name === cartItem.name);
    return food ? food.flavors : [];
  });

  console.log("cartFlavors", cartFlavors);

  const matchingFoods = cartList.flatMap((cartItem) => {
    if (cartItem.flavors && cartItem.flavors.length > 0) {
      return foodItems.filter((foodItem) => foodItem.name === cartItem.name);
    }
    return [];
  });
  console.log("matchingFoods", matchingFoods);

  const matchingFoodsByName = matchingFoods.reduce((acc, foodItem) => {
    acc[foodItem.name] = foodItem;
    return acc;
  }, {});

  console.log("matchingFoodsByName", matchingFoodsByName);

  const handleFlavorSelect = (itemId, itemname, newFlavor) => {
    console.log("flavor", newFlavor);
    console.log("itemId", itemId);
    console.log("itemname", itemname);
    const foundItem = cartList.find(
      (cartItem) => cartItem.name === itemname && cartItem.comboCategoryFoodId == itemId
    );
    console.log("foundItem", foundItem);
    if (!foundItem) {
      return;
    }
    dispatch(
      changeCartComboItemFlavor({
        originId,
        comboCategoryFoodId: itemId,
        flavors: newFlavor,
      })
    );
  };

  // 総価格を計算
  const totalPrice = cartList.reduce((a, c) => a + c.price * c.count, 0);

  const dispatch = useDispatch();

  // カートの開閉状態を制御
  const [visible, setVisible] = useState(false);

  const onShow = () => {
    if (cartList.length > 0 && !visible) {
      setVisible(true);
    } else if (cartList.length > 0 && visible) {
      setVisible(false);
    } else return;
  };
  const handleClick = () => {
    if (token && userInfo.userId) {
      navigate("/checkout"); // tokenとuserIdがある場合、チェックアウトページに遷移
    } else {
      navigate("/messageLogin"); // それ以外の場合、ログインページに遷移
    }
  };
  return (
    <>
      {/* カートリスト */}
      {cartList
        .filter((item) => item.count > 0)
        .map((item) => {
          return (
            <div className="cartComboItem" key={item.comboCategoryFoodId}>
              <img className="shopPic" src={item.picture} alt="" />
              <div className="main">
                <div className="skuInfo">
                  <div className="name">{item.name}</div>

                  <div className="flavors">
                    {item.flavors && (
                      <CartDropdown
                        flavors={matchingFoodsByName[item.name].flavors}
                        defaultFlavor={item.flavors}
                        onFlavorSelect={(newFlavor) =>
                          handleFlavorSelect(item.comboCategoryFoodId, item.name, newFlavor)
                        }
                      ></CartDropdown>
                    )}
                  </div>
                </div>
              </div>
              <div className="skuBtnWrapper btnGroup">
                {/* 数量コンポーネント */}
                <CartComboItemCount
                  count={item.count}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default CartComboItem;